export default class ScriptAppender {
    static appendScripts(scriptsToAppend) {
        let head = document.getElementsByTagName("head")[0];
        const scriptsLoaded = []
        scriptsToAppend.scripts.forEach(tag => {
            let script = document.createElement("script");
            script.type = "text/javascript";
            script.src = tag.url;
            script.async = true;

            if (tag.attributes) {
                for (let [key, value] of Object.entries(tag.attributes)) {
                    script.setAttribute(key, value)
                }
            }

            try {
                head.appendChild(script);
            } catch (err) {
                console.error(err)
            }

            scriptsLoaded.push(true)
            if (scriptsToAppend.callback && scriptsLoaded.length === scriptsToAppend.scripts.length && scriptsLoaded.every(item => { return item })) {
                script.onload = function () {
                    scriptsToAppend.callback();
                };
            }
        });
    }

    static appendSchemaScript(content) {
        try {
            let head = document.getElementsByTagName("head")[0]
            let script = document.createElement("script")
            script.type = "application/ld+json"
            script.innerHTML = JSON.stringify(content, null, 2)
            head.appendChild(script)
        } catch (error) {
            // Do nothing with the error, we dont want to log it out.
        }
    }

    static appendLiteralScript(content) {
        try {
            const head = document.getElementsByTagName("head")[0]
            const script = document.createElement("script")
            script.innerHTML = content
            head.appendChild(script)
        } catch (error) {
            // Do nothing with the error, we dont want to log it out.
        }
    }

    static appendSingleScript(src) {
        try {
            let head = document.getElementsByTagName("head")[0]
            let script = document.createElement("script")
            script.type = "text/javascript"
            script.src = src
            script.async = true;
            head.appendChild(script)
        } catch (error) {
            // Do nothing with the error, we dont want to log it out.
        }
    }

    static appendStyleSheet(src) {
        try {
            let head = document.getElementsByTagName("head")[0]
            let link = document.createElement("link")
            link.setAttribute("rel", "stylesheet");
            link.href = src
            head.appendChild(link)
        } catch (error) {
            // Do nothing with the error, we dont want to log it out.
        }
    }

}
