<template>
    <div id="villasGadget" class="min-h-106 md:min-h-62 lg:min-h-36 mb-1 p-2 w-full bg-secondary">

        <div id="solm_affiliate_card"
                data-affiliate-code="UCKRTCPLZ3" 
                data-url="//www.solmarvillas.com/solm-affiliate-widget/"
                :src="solmarScriptLocation"/>
     </div>
 </template> 
 
 <script>
 import ScriptAppender from "@/ScriptAppender";

 export default {
     name: 'VillasGadget',
     components: {},
     data() {
       return {
        solmarScriptLocation: '//www.solmarvillas.com/modules/custom/solm_affiliate/assets/dist/bundle.min.js',
       };
     },
     props: {
         data: Object,
     },
     mounted() {
      ScriptAppender.appendStyleSheet("//fonts.googleapis.com/css?family=Raleway&display=swap");

      ScriptAppender.appendStyleSheet("//fonts.googleapis.com/css?family=Montserrat&display=swap");

      ScriptAppender.appendStyleSheet("//www.solmarvillas.com/modules/custom/solm_affiliate/assets/dist/main.css");

      ScriptAppender.appendSingleScript("https://code.jquery.com/jquery-3.7.0.min.js");

      ScriptAppender.appendSingleScript(this.solmarScriptLocation)

      ScriptAppender.appendLiteralScript(`window.drupalSettings={node_type:"",origin:"//www.solmarvillas.com",base_url:"//www.solmarvillas.com",referral:"UCKRTCPLZ3",partnar_logo:"https://www.solmarvillas.com/themes/custom/solm/images/solmar-logo-2022.svg",partnar_heading:"Save up to 10% on 2024 Villa Holidays!"}`);
     }
 }
 </script>
